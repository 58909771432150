import styled from "styled-components";
import WA from "./Vector.svg";
import Tele from "./Vector-1.svg";
import Back from "./background.png";
import Back2 from "./bg2.png";
import Truck from "./truck.svg";
import g1 from "./g1.svg";
import g2 from "./g2.svg";
import g3 from "./g3.svg";
import g4 from "./g4.svg";
import { db } from "./firebase"; // Импортируйте вашу конфигурацию Firebase
import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

import { Button, Modal } from 'antd';// Импортируйте необходимые функции

const AppStyled = styled.div`
  background-image: url(${Back});
  color: #fff;
  background-size: cover; /* Изображение масштабируется, чтобы полностью покрыть элемент */
  background-repeat: no-repeat; /* Изображение не повторяется */
  background-position: center; /* Центрирует изображение внутри элемента */

  padding: 40px 0;

  > div {
    > nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      > div {
        display: flex;
        column-gap: 19px;
      }
    }

    > p {
      width: 100%;
    }

    > a {
      color: #fff;
      text-decoration: none;
      border: 1px solid #fff;
      text-align: center;
      display: block;
      width: 140px;
      padding: 15px 0;
      border-radius: 10px;
    }

    > .trucks {
      margin-top: 120px;
      display: flex;
      row-gap: 50px;
      flex-wrap: wrap;
      justify-content: space-between;

      > span {
        display: flex;
        column-gap: 15px;
      }
    }
  }
`;

const AboutStyled = styled.div`
  color: #fff;
  background-color: #000;
  padding: 40px 0;
  > div {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    justify-content: space-between;
    align-items: center;
    > p {
      width: 550px;
    }
  }
`;

const Adventage = styled.div`
  color: #fff;
  padding: 100px 0;
  background-color: #000;
  background-image: url(${Back2});
  background-repeat: no-repeat; /* Изображение не повторяется */
  background-position: center; /* Центрирует изображение внутри элемента */
  > div {
    > div {
      width: 780px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap !important;

      justify-content: space-between;
      > img {
        display: block;
      }
    }
  }
`;

const Statistic = styled.div`
  color: #fff;
  background-color: #000;

  > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 50px;
    > div {
      > hr {
        width: 50px;
        align-items: center;
      }
    }
  }
`;

const Form = styled.div`
  padding: 100px 0 0 0;
  color: #fff;
  background-color: #000;

  > div {
    > div {
      margin-top: 43px;
      display: flex;
      row-gap: 43px;
      flex-wrap: wrap;
      column-gap: 43px;
      > span {
        > input {
          margin-top: 10px;
          padding: 15px;
          border: none;
          outline: none;
          border-radius: 5px;
        }
      }
    }

    > span {
      margin-top: 43px;
      display: flex;
      flex-wrap: wrap;
      row-gap: 20px;
      align-items: center;
      column-gap: 30px;
      > a {
        color: #fff;
        text-decoration: none;
        border: 1px solid #fff;
        text-align: center;
        display: block;
        width: 140px;
        padding: 15px 0;
        border-radius: 10px;
      }
    }
  }
`;

const Footer = styled.div`
  color: #fff;
  background-color: #000;
  padding: 100px 0;

  > div {
    > div {
      display: flex;

      align-items: center;
      justify-content: space-between;
    }
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  padding: 15px;
`;

function App() {
  const [content, setContent] = useState([]);
  const [password, setPassword] = useState('');
  const navigate = useNavigate();


  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await getDocs(collection(db, "content")); // Измените 'content' на ваше название коллекции
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setContent(data);
        console.log(content);

        console.log(content);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);


  const handleLogin = () => {
    // Проверка, если пароль равен 345353234
    if (password === '010106') {
      navigate('/admin'); // Переключение на страницу /admin
    } else {
      alert('Неверный пароль');
    }
  };

  return (
    // {content.map((contents) => <div className="polo" key={contents.id}>  {contents.navAbout}</div>)}

    <>
     <Modal title="Login" open={isModalOpen} onOk={handleLogin } onCancel={handleCancel}>
     <input
     style={{width: '100%', padding: '10px', border:"none",fontSize:"20px"}}
        type="password"
        placeholder="Введите пароль"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      </Modal>
    {content.map((contents) =>
      <>
        <AppStyled>
          <Container>
            <nav>
              <h1>
                CARGO AUTO <br /> TRANSPORTATION
              </h1>

              <div className="polo">
                <span>
                  
                    <span>{contents.navAbout}</span>
                
                </span>
                <span>
                {contents.navRequest}
                  
                </span>
                <span>
                {contents.navPhone}
                
                </span>
                <img src={WA} alt="WhatsApp" />
                <img src={Tele} alt="Telegram" />
              </div>

              <Button style={{background:"none", border:"1px solid #fff"}} size="large" type="primary" onClick={showModal}>
        Login
      </Button>
            </nav>

            <br />
            <br />
            <br />
            <br />

            <h1>
            {contents.mainTitle}
             
              <br />
              {contents.mainSubtitle}
             
            </h1>

            <br />

            <p>
            {contents.mainDescription}
            
            </p>
            <br />
            <br />
            <a href="#">
            {contents.orderNow}
              
            </a>

            <div className="trucks">
             <span>
               <img className="imgg" src={Truck} />
               <p className="textT">
               {contents.track1}
              </p>
            </span>
             <span>
               <img className="imgg" src={Truck} />
               <p className="textT">{contents.track2}</p>
             </span>

             <span>
               <img className="imgg" src={Truck} />
               <p className="textT">
               {contents.track3}
               </p>
             </span>

             <span>
               <img className="imgg" src={Truck} />
               <p className="textT">
                 
                 {contents.track4}
               </p>
             </span>
           </div>
          </Container>
        </AppStyled>

        <AboutStyled>
          <Container>
            <h1>
              CARGO AUTO <br />
              TRANSPORTATION
            </h1>

            <p>
            {contents.aboutDescription}
             
            </p>
          </Container>
        </AboutStyled>

        <Adventage>
          <Container>
            <div className="salom">
              <img src={g1} alt="Advantage 1" />
              <img src={g2} alt="Advantage 2" />
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="salom">
              <img src={g3} alt="Advantage 3" />
              <img src={g4} alt="Advantage 4" />
            </div>
          </Container>
        </Adventage>

        <Statistic>
          <Container>
            <div>
              <h1>
              {contents.clientsCount}
             
              </h1>
              <hr />
              <p className="textt">
              {contents.clientsText}
               
              </p>
            </div>

            <div>
              <h1>
              {contents.cargoDeliveredCount}
            
              </h1>
              <hr />
              <p className="textt">
              {contents.cargoDeliveredText}
            
              
              </p>
            </div>

            <div>
              <h1>
              {contents.yearsCount}
      
              </h1>
              <hr />
              <p className="textt">
              {contents.yearsText}
               
              </p>
            </div>

            <div>
              <h1>
              {contents.employeesCount}
           
              </h1>
              <hr />
              <p className="textt">
              {contents.employeesText}
           
          
              </p>
            </div>
          </Container>
        </Statistic>

        <Form>
          <Container>
            <h1>
              {content.filter((item) => item.type === "formHeader")[0]?.text ||
                "Leave a Request"}
            </h1>
            <br />
            <h4>
              {content.filter((item) => item.type === "formDescription")[0]
                ?.text ||
                "Our manager will get in touch with you shortly to discuss the details of your cargo transportation needs."}
            </h4>
            <div>
              <span>
                <p>
                  {content.filter((item) => item.type === "pickupLabel")[0]
                    ?.text || "Pickup Location"}
                </p>
                <input
                  placeholder={
                    content.filter(
                      (item) => item.type === "pickupPlaceholder"
                    )[0]?.text || "Where to pick up the cargo..."
                  }
                />
              </span>

              <span>
                <p>
                  {content.filter((item) => item.type === "deliveryLabel")[0]
                    ?.text || "Delivery Location"}
                </p>
                <input
                  placeholder={
                    content.filter(
                      (item) => item.type === "deliveryPlaceholder"
                    )[0]?.text || "Where to deliver the cargo..."
                  }
                />
              </span>
            </div>

            <div>
              <span>
                <p>
                  {content.filter((item) => item.type === "nameLabel")[0]
                    ?.text || "How should we address you?"}
                </p>
                <input
                  placeholder={
                    content.filter((item) => item.type === "namePlaceholder")[0]
                      ?.text || "Your name..."
                  }
                />
              </span>

              <span>
                <p>
                  {content.filter((item) => item.type === "phoneLabel")[0]
                    ?.text || "Your Phone Number"}
                </p>
                <input
                  placeholder={
                    content.filter(
                      (item) => item.type === "phonePlaceholder"
                    )[0]?.text || "Enter your phone number..."
                  }
                />
              </span>
            </div>

            <span>
              <a href="#">
                {content.filter((item) => item.type === "submitLabel")[0]
                  ?.text || "Submit Request"}
              </a>
              <p>
                {content.filter((item) => item.type === "privacyPolicy")[0]
                  ?.text ||
                  "By clicking 'Submit Request,' you agree to the processing of your personal data in accordance with our Privacy Policy."}
              </p>
            </span>
          </Container>
        </Form>

        <Footer>
          <Container>
            <hr />
            <br />
            <br />
            <br />
            <div>
              <h3>
                CARGO AUTO <br /> TRANSPORTATION
              </h3>
              <p>2024</p>
            </div>
          </Container>
        </Footer>
      </>
      )}
    </>
  );
}

export default App;

// function App() {
//   return (
//     <>
//       <AppStyled>
//         <Container>
//           <nav>
//             <h1>
//               CARGO AUTO <br /> TRANSPORTATION
//             </h1>
//             <div className="polo">
//               <span>About US</span>
//               <span>Leave a Request</span>
//               <span>+1 (XXX) XXX-XXXX</span>
//               <img src={WA} />
//               <img src={Tele} />
//             </div>
//           </nav>

//           <br />
//           <br />
//           <br />
//           <br />

//           <h1>
//             Reliable Cargo Auto Transportation <br />
//             Across the USA and Internationally
//           </h1>

//           <br />

//           <p>
//             At Cargo Auto Transportation, we provide secure and timely freight
//             services tailored to your needs. <br />
//             Whether you require domestic or international shipping, we handle
//             all types of cargo, from small parcels to oversized and hazardous
//             materials. Trust us to deliver your goods safely, every time.
//           </p>
//           <br />
//           <br />
//           <a href="#">Order Now</a>

//           <div className="trucks">
//             <span>
//               <img src={Truck} />
//               <p>
//                 International and Domestic
//                 <br /> Freight Transportation
//               </p>
//             </span>

//             <span>
//               <img src={Truck} />
//               <p>Consolidated Shipments</p>
//             </span>

//             <span>
//               <img src={Truck} />
//               <p>
//                 Door-to-Door <br /> Delivery
//               </p>
//             </span>

//             <span>
//               <img src={Truck} />
//               <p>
//                 Oversized and <br /> Hazardous Cargo
//               </p>
//             </span>
//           </div>
//         </Container>
//       </AppStyled>

//       <AboutStyled>
//         <Container>
//           <h1>
//             CARGO AUTO <br />
//             TRANSPORTATION
//           </h1>

//           <p>
//             Cargo Auto Transportation is your reliable partner in freight
//             trucking services. We specialize in delivering all types of cargo
//             across the country and internationally. With our experience and
//             professional approach, we guarantee the safety and timely delivery
//             of every shipment.
//           </p>
//         </Container>
//       </AboutStyled>

//       <Adventage>
//         <Container>
//           <div className="salom">
//             <img src={g1} />
//             <img src={g2} />
//           </div>
//           <br />
//           <br />
//           <br />
//           <br />
//           <br />
//           <br />
//           <br />
//           <div className="salom">
//             <img src={g3} />
//             <img src={g4} />
//           </div>
//         </Container>
//       </Adventage>

//       <Statistic>
//         <Container>
//           <div>
//             <h1>3200+</h1>
//             <hr />

//             <p>Clients who trust us</p>
//           </div>

//           <div>
//             <h1>100,000+</h1>
//             <hr />

//             <p>
//               Tons of cargo <br /> delivered on time
//             </p>
//           </div>

//           <div>
//             <h1>8+</h1>
//             <hr />

//             <p>
//               Years of successful <br /> service
//             </p>
//           </div>

//           <div>
//             <h1>34+</h1>
//             <hr />

//             <p>
//               Employees from different <br /> countries working for you
//             </p>
//           </div>
//         </Container>
//       </Statistic>

//       <Form>
//         <Container>
//           <h1>Leave a Request</h1>
//           <br />
//           <h4>
//             Our manager will get in touch with you shortly to <br /> discuss the
//             details of your cargo transportation needs.
//           </h4>
//           <div>
//             <span>
//               <p>Pickup Location</p>
//               <input placeholder="Where to pick up the cargo..." />
//             </span>

//             <span>
//               <p>Delivery Location</p>
//               <input placeholder="Where to deliver the cargo..." />
//             </span>
//           </div>

//           <div>
//             <span>
//               <p>How should we address you?</p>
//               <input placeholder="Your name..." />
//             </span>

//             <span>
//               <p>Your Phone Number</p>
//               <input placeholder="Enter your phone number..." />
//             </span>
//           </div>

//           <span>
//             <a href="#">Submit Request</a>
//             <p>
//               By clicking 'Submit Request,' you agree to the processing of{" "}
//               <br /> your personal data in accordance with our Privacy Policy.
//             </p>
//           </span>
//         </Container>
//       </Form>

//       <Footer>
//         <Container>
//           <hr/>
//           <br/>
//           <br/>
//           <br/>
//           <div>
//           <h3>CARGO AUTO <br/>
//           TRANSPORTATION</h3>

//           <p>2024</p>
//           </div>
//         </Container>
//       </Footer>
//     </>
//   );
// }
