import React, { useEffect, useState } from "react";
import { db } from "./firebase"; // Импортируйте ваш экземпляр Firebase
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";



const AdminStyle = styled.div `
 padding: 30px;
    margin: 0 auto;
    max-width: 1024px;
>form{
    padding: 30px;
    margin: 0 auto;
    max-width: 1024px;
    >input {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        border: none;
        background: #f6f6f6;
        font-size: 20px;
    }
}
`


function App() {
  const [content, setContent] = useState([]);
  const [editId, setEditId] = useState(null);
  const [editContent, setEditContent] = useState({});
  const navigate = useNavigate();


  // Функция для получения данных из Firestore
  const fetchData = async () => {
    try {
      const snapshot = await getDocs(collection(db, "content"));
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setContent(data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  // Используем useEffect для вызова fetchData при загрузке компонента
  useEffect(() => {
    fetchData();
  }, []);

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditContent((prevContent) => ({ ...prevContent, [name]: value }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const contentDoc = doc(db, "content", editId);
      await updateDoc(contentDoc, editContent);
      setEditId(null);
      setEditContent({});
      // Перезагрузите данные после обновления
      fetchData();
    } catch (error) {
      console.error("Error updating data: ", error);
    }
  };

  const handleEdit = (contentItem) => {
    setEditId(contentItem.id);
    setEditContent(contentItem);
  };

  return (
    <>
      

      <AdminStyle>

      <button className="btnn" onClick={() => navigate("/")}>Main Site</button>
      <br />
          <br />
          <br />
          <br />
      <h1>Content List</h1>
      <ul>
        {content.map((item) => (
          <li key={item.id}>
            <strong>{item.mainTitle}</strong>
            <br />
            <br />
            <button className="btnn" onClick={() => handleEdit(item)}>Edit</button>
          </li>
        ))}
      </ul>


      {editId && (
        <form onSubmit={handleEditSubmit}>
          <h2>Edit Content</h2>
          <br />
          <br />
          <input
            type="text"
            name="navAbout"
            value={editContent.navAbout}
            onChange={handleEditChange}
            placeholder="navAbout"
          />
          <br />
          <br />
          <input
            type="text"
            name="navRequest"
            value={editContent.navRequest}
            onChange={handleEditChange}
            placeholder="navRequest"
          />
          <br />
          <br />
          <input
            type="text"
            name="navPhone"
            value={editContent.navPhone}
            onChange={handleEditChange}
            placeholder="navPhone"
          />
          <br />
          <br />
          <input
            type="text"
            name="mainTitle"
            value={editContent.mainTitle}
            onChange={handleEditChange}
            placeholder="Main Description"
          />
          <br />
          <br />
          <input
            type="text"
            name="mainSubtitle"
            value={editContent.mainSubtitle}
            onChange={handleEditChange}
            placeholder="Main Description"
          />
          <br />
          <br />
          <input
            type="text"
            name="mainDescription"
            value={editContent.mainDescription}
            onChange={handleEditChange}
            placeholder="Main Description"
          />
          <br />
          <br />
          <input
            type="text"
            name="orderNow"
            value={editContent.orderNow}
            onChange={handleEditChange}
            placeholder="Main Description"
          />
          <br />
          <br />
          <input
            type="text"
            name="track1"
            value={editContent.track1}
            onChange={handleEditChange}
            placeholder="Main Description"
          />
          <br />
          <br />
          <input
            type="text"
            name="track2"
            value={editContent.track2}
            onChange={handleEditChange}
            placeholder="Main Description"
          />
          <br />
          <br />
          <input
            type="text"
            name="track3"
            value={editContent.track3}
            onChange={handleEditChange}
            placeholder="Main Description"
          />
          <br />
          <br />
          <input
            type="text"
            name="track4"
            value={editContent.track4}
            onChange={handleEditChange}
            placeholder="Main Description"
          />

          <br />
          <br />
          <input
            type="text"
            name="aboutDescription"
            value={editContent.aboutDescription}
            onChange={handleEditChange}
            placeholder="Main Description"
          />

          <br />
          <br />
          <input
            type="text"
            name="clientsCount"
            value={editContent.clientsCount}
            onChange={handleEditChange}
            placeholder="Main Description"
          />

          <br />
          <br />
          <input
            type="text"
            name="clientsText"
            value={editContent.clientsText}
            onChange={handleEditChange}
            placeholder="Main Description"
          />

          <br />
          <br />
          <input
            type="text"
            name="cargoDeliveredCount"
            value={editContent.cargoDeliveredCount}
            onChange={handleEditChange}
            placeholder="Main Description"
          />

          <br />
          <br />
          <input
            type="text"
            name="cargoDeliveredText"
            value={editContent.cargoDeliveredText}
            onChange={handleEditChange}
            placeholder="Main Description"
          />

          <br />
          <br />
          <input
            type="text"
            name="yearsText"
            value={editContent.yearsText}
            onChange={handleEditChange}
            placeholder="Main Description"
          />

          <br />
          <br />
          <input
            type="text"
            name="employeesCount"
            value={editContent.employeesCount}
            onChange={handleEditChange}
            placeholder="Main Description"
          />

          <br />
          <br />
          <input
            type="text"
            name="employeesText"
            value={editContent.employeesText}
            onChange={handleEditChange}
            placeholder="Main Description"
          />

<br />
<br />
<br />
<br />

       

          {/* Добавьте другие поля, которые нужно редактировать */}
          <button className="btnn"  type="submit">Update</button>
          <br />
          <br />
          <button className="btnn" onClick={() => setEditId(null)}>Cancel</button>
        </form>
      )}
      </AdminStyle>
     
    </>
  );
}

export default App;
